@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500&display=swap');
* {
  box-sizing: border-box;
}
body {
  /* background: #1C8EF9 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Figtree', sans-serif;
}
h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Figtree', sans-serif;
}
body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}

.view1-results {
  font-family: 'Figtree', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.view2-title {
  font-family: 'Figtree', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.deviceMenu {
  font-family: 'Figtree', sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
.cameraMenu {
  font-family: 'Figtree', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
}
.list-item {
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
}
table {
  display: flex;
  margin: 5rem auto;
  border: 1rem hidden #000000;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px black;
  overflow: hidden;
  max-width: 150rem;
}

li, p, a, td{
  font-size: 1.6rem;
  line-height: 1.6;
}

thead {
  font-size: 0.7rem;
  background-color: #b2b2fc;
  color: #000000;
}

th,
td {
  border: 1px solid #000000;
  min-width: 5rem;
  padding: 0.3rem;
  line-height: 1;
}

td,ul,li {
  font-size: 0.7rem;
}
